export const EVENT_TYPE_MAPPING = {
  AddCollateral: "Add Collateral",
  AddCollateralNFT: "Add Collateral NFT",
  AddQuoteToken: "Add Quote Token",
  ApproveLPTransferors: "Approve LP Transferors",
  AuctionNFTSettle: "Auction NFT Settle",
  AuctionSettle: "Auction Settle",
  BondWithdrawn: "Bond Withdrawn",
  BucketBankruptcy: "Bucket Bankruptcy",
  BucketTake: "Bucket Take",
  BucketTakeLPAwarded: "Bucket Take LP Awarded",
  DecreaseLPAllowance: "Decrease LP Allowance",
  DrawDebt: "Draw Debt",
  DrawDebtNFT: "Draw Debt NFT",
  Flashloan: "Flashloan",
  IncreaseLPAllowance: "Increase LP Allowance",
  Kick: "Kick",
  KickReserveAuction: "Kick Reserve Auction",
  LoanStamped: "Loan Stamped",
  MergeOrRemoveCollateralNFT: "Merge Or Remove Collateral NFT",
  MoveQuoteToken: "Move Quote Token",
  PoolCreated: "Pool Created",
  RemoveCollateral: "Remove Collateral",
  RemoveQuoteToken: "Remove Quote Token",
  RepayDebt: "Repay Debt",
  ReserveAuction: "Reserve Auction",
  ResetInterestRate: "Reset Interest Rate",
  RevokeLPAllowance: "Revoke LP Allowance",
  RevokeLPTransferors: "Revoke LP Transferors",
  Settle: "Settle",
  Take: "Take",
  TransferLP: "Transfer LP",
  UpdateInterestRate: "Update Interest Rate",
};

export const WALLET_EVENTS = [
  "AddCollateral",
  "AddCollateralNFT",
  "AddQuoteToken",
  "ApproveLPTransferors",
  "AuctionNFTSettle",
  "AuctionSettle",
  "BondWithdrawn",
  "BucketTake",
  "BucketTakeLPAwarded",
  "DrawDebt",
  "DrawDebtNFT",
  "IncreaseLPAllowance",
  "Kick",
  "LoanStamped",
  "MergeOrRemoveCollateralNFT",
  "MoveQuoteToken",
  "RemoveCollateral",
  "RemoveQuoteToken",
  "RepayDebt",
  "Settle",
  "Take",
  "TransferLP",
];

export const BUCKET_EVENTS = [
  "AddQuoteToken",
  "RemoveQuoteToken",
  "MoveQuoteToken",
  "AddCollateral",
  "AddCollateralNFT",
  "RemoveCollateral",
  "BucketTake",
  "IncreaseLPAllowance",
  "TransferLP",
  "BucketBankruptcy",
];

export const POOL_EVENTS = [];

export const AJNA_TOKEN_ADDRESS = "0x9a96ec9b57fb64fbc60b423d1f4da7691bd35079";
