export const DEFAULT_PALETTE = [
  "#8AC7DB",
  "#00d395",
  "#9669ed",
  "#03A9F4",
  "#FF4560",
  "#775DD0",
  "#3F51B5",
  "#4CAF50",
  "#F9CE1D",
  "#008FFB",
  "#00E396",
  "#FEB019",
  "#0276aa",
  "#e20020",
  "#492fa3",
  "#2c387e",
  "#357a38",
  "#bd9905",
  "#0064af",
  "#009e69",
  "#c28000",
  "#014361",
  "#810012",
  "#2a1b5d",
  "#192048",
  "#1e4620",
  "#6c5702",
  "#003964",
  "#005a3c",
  "#6f4900",
  "#22b8fc",
  "#ff6077",
  "#8b75d7",
  "#5767c4",
  "#65bc69",
  "#f9d53e",
];

export const SYMBOLS_PALETTE = {
  WETH: "#858585",
  ETH: "#858585",
  USDC: "#3e73c4",
  WBTC: "#ef8e19",
  BTC: "#ef8e19",
  stETH: "#00a3ff",
  DAI: "#f4b731",
  USDT: "#26a17b",
  USDt: "#26a17b",
  CRV: "#40649f",
  LINK: "#2a5ada",
  TUSD: "#2b2e7f",
  FRAX: "#FFF",
  FEI: "#1d8662",
  MKR: "#1aab9b",
  WAVAX: "#d11504",
  sAVAX: "#f04907",
  Others: "#FFFFFF",
  others: "#FFFFFF",
  AAVE: "#2ebac6",
  YFI: "#006ae3",
  UNI: "#ff007a",
  DPI: "#3c176b",
  BUSD: "#f3ba2f",
  MANA: "#ff2d55",
  SNX: "#47d1f9",
  REN: "#696969",
  BAT: "#ff5000",
  GUSD: "#00dcfa",
  ENS: "#7398f8",
  ENJ: "#624dbf",
  KNC: "#31cb9e",
  RAI: "#52bea1",
  xSUSHI: "#d65892",
  ZRX: "#FFFFF0",
  wstETH: "#00aaff",
  cbETH: "#00f29e",
  LDO: "#F69988",
};
